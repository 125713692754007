import Head from 'next/head'

export default function WebsiteJsonLd () {
  const jsonld = `{
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Sendung verpasst?",
    "url": "${process.env.NEXT_PUBLIC_JSON_LD_ORGANISARTION_URL}",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "${process.env.NEXT_PUBLIC_JSON_LD_ORGANISARTION_URL}/search?q={search_term}",
      "query-input": "required name=search_term"
      }
  }`

  return (
    <Head>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: jsonld }}
        key='jsonld-website'
      />
    </Head>
  )
}
