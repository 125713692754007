import { CardContent, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CDN_SHOWS_BASEURL, getImageURL, replaceUmlauts } from 'lib/utils'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '300px',
    marginLeft: '1px',
    marginRight: '1px',
    marginBottom: '3px'
  },
  image: {
    position: 'relative',
    objectFit: 'fill',
    '&[alt]:after': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(' + theme.palette.background.default + ', ' + lighten(theme.palette.background.default, 0.5) + ')',
      color: 'rgba(0, 0, 0, 0)',
      content: 'attr(alt)'
    }
  },
  title: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    height: '40px'
  },
  loading: {
    marginTop: '-100px'
  }
}))

export default function CardRecommendation ({ showOrSeries, index }) {
  const classes = useStyles()

  const imageUrl = index === 'shows' ? getImageURL(showOrSeries.image, showOrSeries.stations[0].slug) : CDN_SHOWS_BASEURL + '/series/' + replaceUmlauts(showOrSeries.slug) + '.jpg'
  const title = index === 'shows' ? showOrSeries.fullTitle : showOrSeries.name
  const href = index === 'shows' ? `/content/${showOrSeries.slug}` : `/sendungen/${showOrSeries.slug}`

  return (
    <Card className={classes.card}>
      <CardActionArea
        className='recommendation'
        href={href}
      >
        <span>
          <CardMedia
            component='img'
            width='300'
            height='169'
            src={imageUrl}
            title={title}
            alt={title}
            className={classes.image}
            loading='lazy'
          />
          <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
        </span>
        <CardContent>
          <Typography variant='body1' component='h3' className={classes.title} gutterBottom dangerouslySetInnerHTML={{ __html: title }} />
          <Typography variant='body2' component='h3' className={classes.subtitle} dangerouslySetInnerHTML={{ __html: showOrSeries.subtitle }} />
        </CardContent>

      </CardActionArea>
    </Card>
  )
}
