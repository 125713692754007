import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import { Link2Category } from 'lib/links'
import { Navigation, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const useStyles = makeStyles((theme) => ({
  swiperSlide: {
    width: '202px',
    marginRight: theme.spacing(1)
  },
  card: {
    color: '#fff',
    width: '200px',
    marginLeft: '1px',
    marginRight: '1px',
    marginBottom: '3px'
  },
  image: {
    position: 'relative',
    '&[alt]:after': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(' + theme.palette.background.default + ', ' + lighten(theme.palette.background.default, 0.5) + ')',
      color: 'rgba(0, 0, 0, 0)',
      content: 'attr(alt)'
    }
  },
  content: {
    textAlign: 'center',
    paddingBottom: '8px !important'
  },
  section: {
    marginBottom: theme.spacing(7)
  }
}))

export default function SwiperCategories ({ categories }) {
  const classes = useStyles()

  return (
    <section className={classes.section}>
      <Typography component='h2' variant='h2' gutterBottom>Alle Rubriken</Typography>
      <Swiper
        modules={[Navigation, A11y]}
        navigation
        slidesPerView='auto'
        lazyPreloadPrevNext={2}
        watchOverflow
        breakpoints={{
          450: {
            slidesPerGroup: 2,
            lazy: { loadPrevNextAmount: 4 }
          },
          670: {
            slidesPerGroup: 3,
            lazy: { loadPrevNextAmount: 6 }
          },
          880: {
            slidesPerGroup: 4,
            lazy: { loadPrevNextAmount: 8 }
          },
          1080: {
            slidesPerGroup: 5,
            lazy: { loadPrevNextAmount: 10 }
          },
          1300: {
            slidesPerGroup: 6,
            lazy: { loadPrevNextAmount: 12 }
          }
        }}
      >
        {categories.map((category) => (
          <SwiperSlide key={category.slug} className={classes.swiperSlide}>
            <Card className={classes.card}>
              <Link2Category slug={category.slug}>
                <CardActionArea>
                  <span>
                    <CardMedia
                      component='img'
                      height='200'
                      width='200'
                      src={'/images/categories/' + category.slug + '-card.png'}
                      title={category.name}
                      alt={category.name}
                      className={classes.image}
                      loading='lazy'
                    />
                    <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
                  </span>
                  <CardContent className={classes.content}>
                    <Typography gutterBottom variant='h6' component='h3' className={classes.title}>
                      {category.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Link2Category>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
