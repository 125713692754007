import Head from 'next/head'

export default function OrganizationJsonLd () {
  const jsonld = `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Sendung verpasst?",
    "legalName": "Sendung verpasst UG (haftungsbeschränkt)",
    "url": "${process.env.NEXT_PUBLIC_JSON_LD_ORGANISARTION_URL}",
    "logo": "${process.env.NEXT_PUBLIC_JSON_LD_ORGANISARTION_LOGO}"
  }`

  return (
    <Head>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{ __html: jsonld }}
        key='jsonld-organization'
      />
    </Head>
  )
}
