import React, { useEffect } from 'react'

export default function Ad ({ slot, classname, fullWidth = false, formatAuto = false }) {
  const isActive = () => {
    const adSenseEnabled = process.env.NEXT_PUBLIC_ADSENSE_ENABLED === 'true'
    return adSenseEnabled && slot
  }

  useEffect(() => {
    try {
      if (isActive && window) {
        (window.adsbygoogle = window.adsbygoogle || []).push({})
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  if (!isActive()) {
    return ''
  }

  return (
    <ins
      className={`adsbygoogle ${classname}`}
      style={{ display: 'inline-block' }}
      data-ad-client='ca-pub-3093493265811991'
      data-ad-slot={slot}
      data-full-width-responsive={fullWidth}
      data-ad-format={formatAuto === true ? 'auto' : null}
    />
  )
}
