import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import { Link2Show } from 'lib/links'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '340px',
    marginLeft: '1px',
    marginRight: '1px',
    marginBottom: '3px'
  },
  image: {
    position: 'relative',
    '&[alt]:after': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(' + theme.palette.background.default + ', ' + lighten(theme.palette.background.default, 0.5) + ')',
      color: 'rgba(0, 0, 0, 0)',
      content: 'attr(alt)'
    }
  },
  subtitle: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    position: 'absolute',
    bottom: 0,
    padding: '5px',
    fontWeight: 'bold',
    display: 'inline-block',
    textAlign: 'center'
  },
  loading: {
    marginTop: '-100px'
  }
}))

export default function CardSeries ({ series }) {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Link2Show slug={series.slug}>
        <CardActionArea>
          <span>
            <CardMedia
              component='img'
              width='340'
              height='113'
              src={'/images/sendungen/' + series.slug + '.jpg'}
              title={series.name}
              alt={series.name}
              className={classes.image}
              loading='lazy'
            />
            <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
          </span>
          {series.subtitle &&
            <span className={classes.subtitle}>{series.subtitle}</span>}
        </CardActionArea>
      </Link2Show>
    </Card>
  )
}
