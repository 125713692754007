import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import CardSeries from 'components/CardSeries'
import Link from 'next/link'
import React from 'react'
import { Navigation, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as gtag from 'lib/gtag'

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(7)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  showAll: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main
    }
  },
  swiperSlide: {
    width: '342px',
    marginRight: theme.spacing(1)
  },
  next: {
    border: '1px solid red'
  }
}))

export default function SwiperSeries ({ title, items, counter = false, clicks = false, href }) {
  const classes = useStyles()

  const logEvent = (event) => {
    gtag.event('alle_anzeigen')
  }

  if (items && items.length > 0) {
    return (
      <section className={classes.section}>
        <div className={classes.header}>
          <Typography component='h2' variant='h2' gutterBottom>{title}</Typography>
          {href
            ? <Link href={href} prefetch={false}><a className={classes.showAll} onClick={logEvent}>Alle anzeigen</a></Link>
            : <span />}
        </div>
        <Swiper
          modules={[Navigation, A11y]}
          navigation
          slidesPerView='auto'
          lazyPreloadPrevNext={2}
          watchOverflow
          className='row-series'
          breakpoints={{
            740: {
              slidesPerGroup: 2,
              lazy: { loadPrevNextAmount: 4 }
            },
            1090: {
              slidesPerGroup: 3,
              lazy: { loadPrevNextAmount: 6 }
            }
          }}
        >
          {items.map(({ _id, _source }, index) => (
            <SwiperSlide key={_id} className={classes.swiperSlide}>
              <CardSeries series={_source} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    )
  } else {
    return (
      <span>&nbsp;</span>
    )
  }
}
