import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link2Station } from 'lib/links'

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
    display: 'flex',
    height: '90px',
    width: '120px',
    marginLeft: '1px',
    marginRight: '1px',
    marginBottom: '3px'
  },
  actionArea: {
    display: 'flex',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  image: {
    width: '88px',
    pointerEvents: 'none',
    position: 'relative',
    '&[alt]:after': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: 'attr(alt)',
      backgroundColor: theme.palette.background.paper
    }
  }
}))

export default function CardStation ({ station, inSwiper = false }) {
  const classes = useStyles()

  return (
    <div key={station.slug} className={classes.swiperSlide}>
      <Card className={classes.card} data-test='card-station'>
        <Link2Station slug={station.slug}>
          <CardActionArea className={classes.actionArea}>
            <span>
              <CardMedia
                component='img'
                src={inSwiper ? station.logoWhite : undefined}
                image={!inSwiper ? station.logoWhite : undefined}
                title={station.name}
                alt={station.name}
                className={classes.image}
                width={88}
                height={49}
                loading='lazy'
              />
              {inSwiper &&
                <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />}
            </span>
          </CardActionArea>
        </Link2Station>
      </Card>
    </div>
  )
}
