import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Typography from '@material-ui/core/Typography'
import { Link2Video } from 'lib/links'
import { getImageURL } from 'lib/utils'
import LazyLoad from 'react-lazyload'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '200px',
    marginLeft: '1px',
    marginRight: '1px',
    marginBottom: '3px'
  },
  duration: {
    position: 'absolute',
    top: '123px',
    right: 0,
    backgroundColor: 'rgba(0,0,0,.7)',
    color: 'white',
    textAlign: 'right',
    fontWeight: 'bold',
    fontSize: '11px',
    padding: '1px 3px'
  },
  position: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    position: 'absolute',
    top: 0,
    padding: '5px',
    fontWeight: 'bold',
    display: 'inline-block',
    width: '20px',
    textAlign: 'center'
  },
  clicks: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '254px',
    right: 0,
    padding: '5px',
    fontWeight: 'bold',
    display: 'inline-block'
  },
  timeRemaining: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginTop: '8px'
  },
  fullTitle: {
    height: '70px',
    overflow: 'hidden'
  },
  show: {
    lineHeight: '1.1',
    fontWeight: 'bold'
  },
  title: {
    lineHeight: '1.1'
  },
  image: {
    position: 'relative',
    '&[alt]:after': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(' + theme.palette.background.default + ', ' + lighten(theme.palette.background.default, 0.5) + ')',
      color: 'rgba(0, 0, 0, 0)',
      content: 'attr(alt)'
    }
  },
  date: {
    marginTop: theme.spacing(1)
  },
  loading: {
    marginTop: '-100px'
  }
}))

export default function CardShow ({ props, position = null, clickCounter = null, expiryDate = false, inSwiper = false, cardChanged }) {
  const classes = useStyles()

  const clicksLabel = (clicks) => {
    if (clicks > 1) {
      return clicks.toLocaleString('de-DE') + ' Aufrufe'
    } else {
      return '1 Aufruf'
    }
  }

  const timeRemainingAsString = (timeRemaining) => {
    if (!timeRemaining) return null

    let message
    if (timeRemaining === 1) {
      message = 'noch 1 Stunde'
    } else if (timeRemaining > 1 && timeRemaining < 24) {
      message = `noch ${timeRemaining} Stunden`
    } else if (timeRemaining >= 24 && timeRemaining < 48) {
      message = 'noch 1 Tag'
    } else if (timeRemaining >= 48) {
      const days = Math.floor(timeRemaining / 24)
      message = `noch ${days} Tage`
    } else {
      message = null
    }
    return message
  }

  return (
    <Card className={classes.card}>
      <Link2Video slug={props.slug}>
        <CardActionArea>
          {inSwiper
            ? (
              <span>
                <CardMedia
                  component='img'
                  height='140'
                  width='200'
                  src={getImageURL(props.image, props.stations[0].slug)}
                  loading='lazy'
                  alt={props.fullTitle}
                  className={classes.image}
                />
                <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
              </span>
              )
            : (
              <LazyLoad hight={140} once>
                <CardMedia
                  component='img'
                  height='140'
                  width='200'
                  image={getImageURL(props.image, props.stations[0].slug)}
                  alt={props.fullTitle}
                  className={classes.image}
                  loading='lazy'
                />
              </LazyLoad>
              )}
          {position &&
            <Typography gutterBottom variant='body2' className={classes.position}>{position}</Typography>}
          {clickCounter &&
            <Typography gutterBottom variant='body2' className={classes.clicks} data-test='clicks'>{clicksLabel(props.clicks)}</Typography>}
          <span className={classes.duration}>{props.duration} min</span>
          <CardContent>
            <Typography gutterBottom variant='body2' color='textSecondary' component='p'>
              {props.stations[0].name}
            </Typography>
            <Title show={props} />
            {!expiryDate
              ? <Typography variant='body2' color='textSecondary' component='p' className={classes.date}>{props.date}</Typography>
              : <Typography variant='body2' className={classes.timeRemaining}>{timeRemainingAsString(props.remainingHours)}</Typography>}
          </CardContent>
        </CardActionArea>
      </Link2Video>
    </Card>
  )
}

function Title ({ show }) {
  const classes = useStyles()

  if (show.title) {
    return (
      <div className={classes.fullTitle}>
        <Typography
          variant='body1'
          component='h3'
          className={classes.show}
          gutterBottom
          dangerouslySetInnerHTML={{ __html: show.show }}
        />
        <Typography
          gutterBottom variant='body2'
          component='h3'
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: show.title }}
        />
      </div>
    )
  } else {
    return (
      <div className={classes.fullTitle}>
        <Typography
          gutterBottom
          variant='body1'
          component='h3'
          className={classes.show}
          dangerouslySetInnerHTML={{ __html: show.fullTitle }}
        />
      </div>
    )
  }
}
